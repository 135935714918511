import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./spinner.scss";

const Spinner = ({ size = undefined, className = undefined }) => (
  <span
    className={classNames(
      "spinner",
      className,
      { [`spinner--${size}`]: size },
    )}
  />
);

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["lg", "xl", "ul", "xxl"]),
};

export default Spinner;
