export default class SspRegistrationData {
  identifier = "";

  signUpLastname = "";

  signUpFirstname = "";

  signUpEmailAddress = "";

  userEmailAddress = "";

  password = "";
}
