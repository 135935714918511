import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { useGetDeployStateQuery } from "../../features/netlifyApiSlice";
import useEventListener from "../../hooks/haiku/useEventListener";
import { ReactComponent as IconDelete18 } from "../../icons/icon/18/a-icon-delete-18.svg";
import { ReactComponent as IconLearnMore18 } from "../../icons/icon/18/a-icon-learnmore-18.svg";
import { ReactComponent as IconLink18 } from "../../icons/icon/18/a-icon-link-18.svg";
import { ReactComponent as IconStatusCanceled24 } from "../../icons/icon/24/a-icon-status-canceled-24.svg";
import { ReactComponent as IconStatusEnqueued24 } from "../../icons/icon/24/a-icon-status-enqueued-24.svg";
import { ReactComponent as IconStatusFailed24 } from "../../icons/icon/24/a-icon-status-failed-24.svg";
import { ReactComponent as IconStatusPublished24 } from "../../icons/icon/24/a-icon-status-published-24.svg";
import { ReactComponent as IconStatusReady24 } from "../../icons/icon/24/a-icon-status-ready-24.svg";
import copyToClipboard from "../../utils/clipboardHelper";
import { formatInTimeZone } from "../../utils/dateHelper";
import Spinner from "../spinner/spinner";

import "./previewStatusWidget.scss";

const PreviewStatusWidget = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverData, setPopoverData] = useState(null);

  const wrapperRef = useRef(null);
  const closeTimeout = useRef(null);

  const deployState = useGetDeployStateQuery(null, { pollingInterval: 16000 });

  // Hide popover on outside click
  useEventListener("click", (event) => {
    if (event.target !== wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setPopoverVisible(false);
    }
  });

  const showPopover = (data, autoHide = false) => {
    clearTimeout(closeTimeout.current);

    setPopoverData(data);
    setPopoverVisible(true);

    if (autoHide) {
      closeTimeout.current = setTimeout(() => {
        setPopoverVisible(false);
      }, 4000);
    }
  };

  let state;
  let stateText;
  let stateIcon;
  let stateInfoHeadline;
  let stateInfoText;

  if (deployState.isSuccess) {
    switch (deployState.data.state) {
      case "new":
      case "enqueued":
        state = "enqueued";
        stateText = "In Queue";
        stateIcon = <IconStatusEnqueued24 />;
        stateInfoHeadline = "Awaiting Capacity";
        stateInfoText = "The build was queued since the number of concurrent builds exceeds the capacity of the network.";
        break;

      case "building":
      case "uploading":
      case "uploaded":
      case "preparing":
      case "prepared":
      case "processing":
      case "processed":
      case "retrying":
        state = "building";
        stateText = "Building ...";
        stateIcon = <Spinner size="lg" className="text-learn-area" />;
        stateInfoHeadline = "Preview is building";
        stateInfoText = "The preview is currently being built. This might take a few minutes.";
        break;

      case "ready":
        if (previewWidget.BUILD_ID !== deployState.data.build_id) {
          state = "ready";
          stateText = "New Version available";
          stateIcon = <IconStatusReady24 />;
          stateInfoHeadline = "Preview successfully created";
          stateInfoText = (
            <>
              <div className="mb-12">
                A new preview version is ready. Click on the button below
                or refresh your browser to view the new version.
              </div>
              <button
                type="button"
                className="btn btn-sm btn-dark"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload Page
              </button>
            </>
          );

          break;
        }

        state = "published";
        stateText = null;
        stateIcon = <IconStatusPublished24 />;
        stateInfoHeadline = <span className="text-success">Preview is up-to-date</span>;
        stateInfoText = "You are viewing the current version of the preview.";
        break;

      case "error":
        if (deployState.data.error_message === "Canceled build") {
          state = "canceled";
          stateText = "Canceled";
          stateIcon = <IconStatusCanceled24 />;
          stateInfoHeadline = "Preview canceled";
          stateInfoText = "The preview has been canceled. Please contact the agency for more information.";
        }

        state = "error";
        stateText = "failed";
        stateIcon = <IconStatusFailed24 />;
        stateInfoHeadline = "Error";
        stateInfoText = deployState.data.error_message;
        break;

      default:
        state = "unknown";
        stateText = "Unknown";
        stateIcon = null;
    }
  }

  useEffect(() => {
    if (state === "ready") {
      showPopover([
        { headline: stateInfoHeadline, text: stateInfoText },
      ]);

      return;
    }

    setPopoverVisible(false);
  }, [state]);

  return (
    <div
      ref={wrapperRef}
      className="preview-status-widget-wrapper"
    >
      <div className={classNames("preview-status-widget-popover", { "preview-status-widget-popover--visible": popoverVisible })}>
        <button
          type="button"
          className="preview-status-widget-popover__close no-style"
          onClick={() => setPopoverVisible(false)}
        >
          <IconDelete18 />
        </button>

        {popoverData && popoverData.map(({ headline, text }) => (
          <div key={headline + text}>
            {headline && <div className="typo-small-label">{headline}</div>}
            {text && <div className="typo-body2 text-grey-dark">{text}</div>}
          </div>
        ))}
      </div>

      <div className="preview-status-widget">
        <div>
          <button
            type="button"
            className="no-style"
            disabled={stateInfoHeadline === undefined && stateInfoText === undefined}
            onClick={() => {
              showPopover([
                { headline: stateInfoHeadline, text: stateInfoText },
              ]);
            }}
          >
            {deployState.isLoading && <Spinner size="lg" className="text-learn-area" />}

            {deployState.isSuccess && (
              <>
                {stateIcon}
                {stateText && (
                  <span
                    className={classNames(
                      "preview-status-widget__state",
                      `preview-status-widget__state--${state}`,
                    )}
                  >
                    {stateText}
                  </span>
                )}
              </>
            )}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="no-style"
            disabled={!deployState.isSuccess || deployState.data.created_at === null}
            onClick={() => {
              showPopover([
                {
                  headline: "Preview requested:",
                  text: formatInTimeZone(new Date(deployState.data.created_at), "Ppp"),
                },
                ...deployState.data.published_at ? [{
                  headline: "Preview completed:",
                  text: formatInTimeZone(new Date(deployState.data.published_at), "Ppp"),
                }] : [],
              ]);
            }}
          >
            <IconLearnMore18 />
          </button>
        </div>
        <div>
          <button
            type="button"
            className="no-style"
            onClick={() => {
              copyToClipboard(window.location.href, () => {
                showPopover([
                  { headline: "Preview link copied!" },
                ], true);
              });
            }}
          >
            <IconLink18 />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewStatusWidget;
