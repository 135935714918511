import React, { useEffect } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";

import { useGenerateDonorCardWalletMutation, useGetStatusInfoQuery } from "../../features/io/ioSspApiSlice";
import { ReactComponent as IconRetry24 } from "../../icons/icon/24/a-icon-retry-24.svg";
import { notificationService } from "../../services/notification.service";
import { getUserInfo } from "../../services/sspAuthentication.service";
import { getRegState } from "../../utils/getRegState";
import getUserRole from "../../utils/getUserRole";
// eslint-disable-next-line import/no-cycle
import LinkButton from "../button/linkButton";
import Spinner from "../spinner/spinner";

const navigationLinkActions = (action) => {
  const intl = useIntl();

  let generateDonorCardWallet;
  let generateDonorCardWalletResult;
  let statusInfo;

  const generateDonorCardWalletAction = () => {
    const userInfo = getUserInfo();
    const apiRole = getUserRole(userInfo);
    const regStatus = getRegState(statusInfo?.data, apiRole);

    generateDonorCardWallet({ apiRole, regStatus });
  };

  switch (action) {
    case "action:add-to-wallet":
      [
        generateDonorCardWallet,
        generateDonorCardWalletResult,
      ] = useGenerateDonorCardWalletMutation();
      statusInfo = useGetStatusInfoQuery();

      useEffect(() => {
        if (generateDonorCardWalletResult.isError) {
          notificationService.error(
            intl.formatMessage(
              {
                id: typeof generateDonorCardWalletResult.error.status === "undefined"
                  ? "add_to_wallet.connection_error" : "add_to_wallet.create_error",
              },
              { error: `Error ${generateDonorCardWalletResult.error.status} - ${generateDonorCardWalletResult.error.data}` },
            ),
            {
              action: (
                <LinkButton onClick={() => generateDonorCardWalletAction()}>
                  <FormattedMessage id="add_to_wallet.create_error.retry" /> <IconRetry24 />
                </LinkButton>
              ),
              autoClose: false,
              dismissButtonText: <FormattedMessage id="add_to_wallet.create_error.hide" />,
            },
          );
        }
      }, [generateDonorCardWalletResult]);

      return {
        action: (event) => {
          event.preventDefault();

          generateDonorCardWalletAction();

          return false;
        },
        childrenOverwrite: generateDonorCardWalletResult.isLoading && <Spinner className="text-grey-dark" />,
      };
    default:
      return null;
  }
};

export default navigationLinkActions;
