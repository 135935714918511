export default class PaymentData {
  amount = "";

  birthdate = "";

  giftAidAmount = "";

  donorType = "private";

  donationBoxId = "";

  interval = "";

  salutation = "";

  gender = "";

  firstname = "";

  lastname = "";

  emailAddress = "";

  street = "";

  houseNo = "";

  postCode = "";

  city = "";

  apartment = "";

  careOfAddress = "";

  companyCareOfAddress = "";

  companyApartment = "";

  companyName = "";

  companyStreet = "";

  companyHouseNo = "";

  companyPostCode = "";

  companyCity = "";

  agreeGiftAid = false;

  mobileNumber = "";

  keepInTouchEmail = "";

  keepInTouchPost = "";

  accountHolderName = "";

  accountNumber = "";

  sortCode = "";

  county = "";

  houseName = "";

  identification = "";

  locality = "";

  state = "";

  donationReceipt = "NO";
}
