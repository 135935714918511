import { createSlice } from "@reduxjs/toolkit";

import PaymentData from "../components/paymentDialog/paymentData";
import RegistrationData from "../components/registrationDialog/registrationData";
import SetupData from "../components/virtualDriveSetUpModal/setupData";
import { trimObjectValues } from "../utils/formik/objectUtils";

const initialState = {
  error: false,
  isNavigationHeaderEnabled: true,
  lastSuccess: 0,
  maxDonate: false,
  paymentData: new PaymentData(),
  registrationData: new RegistrationData(),
  setupData: new SetupData(),
  showRegistrationReminder: false,
  stepError: false,
  success: false,
  truncateSteps: true,
  warning: {},
};

const RegistrationSlice = createSlice({
  initialState,
  name: "registration",
  reducers: {
    replaceRegistrationData: (state, { payload }) => ({
      ...initialState,
      lastSuccess: state.lastSuccess,
      registrationData: {
        ...new RegistrationData(),
        ...trimObjectValues(payload),
      },
    }),
    resetRegistration: (state) => ({
      ...initialState,
      lastSuccess: state.lastSuccess,
    }),
    resetVirtualDriveSetup: () => initialState,
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setMaxDonate: (state, { payload }) => {
      state.maxDonate = payload;
    },
    setNavigationHeaderEnabled: (state, { payload }) => {
      state.isNavigationHeaderEnabled = payload;
    },
    setPaymentData: (state, { payload }) => {
      state.paymentData = {
        ...state.paymentData,
        ...trimObjectValues(payload),
      };
    },
    setRegistrationData: (state, { payload }) => {
      state.registrationData = {
        ...state.registrationData,
        ...trimObjectValues(payload),
      };
    },
    setSetupData: (state, { payload }) => {
      state.setupData = {
        ...state.setupData,
        ...trimObjectValues(payload),
      };
    },
    setShowRegistrationReminder: (state, { payload }) => {
      state.showRegistrationReminder = payload;
    },
    setStepError: (state, { payload }) => {
      state.stepError = payload;
    },
    setSuccess: (state) => {
      state.success = true;
      state.lastSuccess = (new Date()).getTime();
    },
    setTruncateSteps: (state, { payload }) => {
      state.truncateSteps = payload;
    },
    setWarning: (state, { payload }) => {
      state.warning = payload;
    },
  },
});

export const {
  replaceRegistrationData,
  resetRegistration,
  resetVirtualDriveSetup,
  setTruncateSteps,
  setError,
  setMaxDonate,
  setNavigationHeaderEnabled,
  setShowRegistrationReminder,
  setPaymentData,
  setRegistrationData,
  setSetupData,
  setStepError,
  setSuccess,
  setWarning,
} = RegistrationSlice.actions;

export default RegistrationSlice.reducer;
