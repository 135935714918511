import { object, ref, string } from "yup";

import { RegexPatterns } from "../dynamicForm/validators";

const profileValidationSchema = (intl) => {
  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });
  const passwordNotEqual = intl.formatMessage({ id: "form.error.password_not_equal" });
  const passwordValidationMessage = intl.formatMessage({ id: "form.error.password_validation" });
  const emailMessage = intl.formatMessage({ id: "form.error.invalid_email" });

  switch (process.env.GATSBY_SITE) {
    case "de":
    case "gb":
      return object().shape({
        alternatePhone: string().matches(
          /^[\d\s\-+/]{5,25}$/,
          intl.formatMessage({ id: "form.error.phone_pattern" }),
        ).when(["mobile"], {
          is: (mobile) => !mobile,
          then: (schema) => (
            schema.required(intl.formatMessage({ id: "form.error.one_phone_number" }))
          ),
        }),
        apartment: string()
          .max(100, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 100,
            },
          ))
          .matches(
            /^[^\u0600-\u06FF]*$/,
            intl.formatMessage({ id: "form.error.invalid_value" }),
          )
          .nullable(),
        city: string()
          .required(requiredMessage)
          .min(2, requiredMessage)
          .max(50, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 50,
            },
          ))
          .matches(
            /^[^\u0600-\u06FF]*$/,
            intl.formatMessage({ id: "form.error.invalid_value" }),
          ),
        co: string()
          .max(100, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 100,
            },
          ))
          .matches(
            /^[^\u0600-\u06FF]*$/,
            intl.formatMessage({ id: "form.error.invalid_value" }),
          )
          .nullable(),
        confirmPassword: string()
          .oneOf([ref("password"), null], passwordNotEqual),
        email: string()
          .required(requiredMessage)
          .max(100, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 100,
            },
          ))
          .email(emailMessage)
          .matches(RegexPatterns.email, emailMessage),
        firstname: string()
          .min(2, requiredMessage)
          .required(requiredMessage)
          .max(40, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 40,
            },
          ))
          .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage),
        gender: string(),
        houseno: string()
          .required(requiredMessage)
          .max(10, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 10,
            },
          ))
          .matches(
            /^[0-9]+[a-zA-Z0-9 \-/]*$/,
            intl.formatMessage({ id: "form.error.invalid_value" }),
          ),
        lastname: string()
          .min(2, requiredMessage)
          .required(requiredMessage)
          .max(100, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 100,
            },
          ))
          .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage),
        mobile: string().matches(
          /^[\d\s\-+/]{5,25}$/,
          intl.formatMessage({ id: "form.error.phone_pattern" }),
        ).when(["alternatePhone"], {
          is: (alternatePhone) => !alternatePhone,
          then: (schema) => (
            schema.required(intl.formatMessage({ id: "form.error.one_phone_number" }))
          ),
        }),
        password: string()
          .min(8, passwordValidationMessage)
          .matches(
            /^(?=.*[0-9])(?=.*[a-zà-öø-ÿ])(?=.*[A-ZÀ-ÖØ-ß])(?=.*[!-/:-@[-`{-~€¡-¬®-¿]).{8,}$/,
            passwordValidationMessage,
          ),
        street: string()
          .required(requiredMessage)
          .min(2, requiredMessage)
          .max(50, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 50,
            },
          ))
          .matches(
            /^[^\u0600-\u06FF]*$/,
            intl.formatMessage({ id: "form.error.invalid_value" }),
          ),
        zipcode: string()
          .required(requiredMessage)
          .max(5, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 5,
            },
          ))
          .matches(
            /^[0-9]{5}$/,
            intl.formatMessage({ id: "form.error.zipcode" }),
          ),
      }, [["mobile", "alternatePhone"]]);
    default: return null;
  }
};

export default profileValidationSchema;
