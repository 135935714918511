import React from "react";

import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import navigationLinkActions from "./navigationLinkActions";
import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import getInternalLinkById from "../../utils/getInternalLinkById";
import iconHelper from "../../utils/iconHelper";
import Spinner from "../spinner/spinner";

const NavigationLink = ({
  activeClassName = undefined,
  children = undefined,
  className = undefined,
  elements = null,
  href: hrefProp = null,
  id = undefined,
  idName = null,
  loading = false,
  loadingWithPostText = false,
  loadingWithPreText = false,
  onClick = null,
  onMouseEnter = () => {},
  partiallyActive = undefined,
  state = null,
  target = null,
  title: titleProp = null,
  typeOverwrite = null,
  urlParameter = null,
  ...futherProps
}) => {
  const isLowerLg = useMediaQuery("(max-width: 991px)", true);

  let external = false;
  let linkChildren = null;
  let href = null;

  const data = useStaticQuery(graphql`
    {
      siteSettings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          faq_page_navigation_item {
            value {
              id
            }
          }
        }
      }
      faqAnchorLinks: allKontentItemFaq {
        nodes {
          id
          system {
            id
          }
          elements {
            anchor_slug {
              value
            }
          }
        }
      }
    }
  `);

  if (elements) {
    linkChildren = elements.title.value;

    if (elements.icon?.value.length > 0) {
      linkChildren = <>{iconHelper(elements.icon.value[0].codename)}{linkChildren}</>;
    }

    if (elements.external_url.value) {
      external = /^(http|\/\/)/.test(elements.external_url.value);
      href = elements.external_url.value;
    } else {
      href = id;
    }
  }

  let text = linkChildren || children;

  if (loadingWithPreText) {
    text = (
      <div className="d-flex">
        {text}<Spinner className="text-grey-dark" size={!isLowerLg ? "lg" : undefined} />
      </div>
    );
  } else if (loadingWithPostText) {
    text = (
      <div className="d-flex">
        <Spinner className="text-grey-dark" size={!isLowerLg ? "lg" : undefined} />{text}
      </div>
    );
  } else if (loading) {
    text = <Spinner className="text-grey-dark" size={!isLowerLg ? "lg" : undefined} />;
  }

  if (hrefProp) {
    external = /^(http|\/\/|mailto:|tel:)/.test(hrefProp);
    href = hrefProp;
  }

  if (!href) {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={typeOverwrite || "button"}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        className={className}
        title={titleProp}
        {...futherProps}
      >
        {text}
      </button>
    );
  }

  if (href.startsWith("action:")) {
    const { action, childrenOverwrite } = navigationLinkActions(href);

    if (action !== null) {
      return (
        <button
          type="button"
          className={className}
          onMouseEnter={onMouseEnter}
          onClick={action}
          title={titleProp}
        >
          {childrenOverwrite || text}
        </button>
      );
    }
  }

  if (!external) {
    let title = "";
    let link = getInternalLinkById(href);
    let faqAnchorLink = false;

    if (!link) {
      const faqLinks = data && data.faqAnchorLinks ? data.faqAnchorLinks.nodes : [];
      link = faqLinks.find((o) => o.system.id === href);

      if (link) {
        faqAnchorLink = true;
      }
    }

    if (link) {
      href = link.slug;
      title = titleProp || link.title;

      if (
        faqAnchorLink
        && data.siteSettings?.elements?.faq_page_navigation_item?.value?.length > 0
      ) {
        const faqPage = getInternalLinkById(
          data.siteSettings.elements.faq_page_navigation_item.value[0].id,
        );

        href = faqPage?.slug ? `${faqPage.slug}#${link.elements.anchor_slug.value}` : link.slug;
      }
    } else {
      // todo: dont show id, if not found
      href = (/^(?!\/)/.test(href)) && !href.startsWith("#") ? `/${href}` : href;
      title = titleProp || title;
    }

    if (urlParameter) {
      href += urlParameter;
    }

    if (target && !faqAnchorLink) {
      return (
        <a
          href={href}
          className={className}
          onMouseEnter={onMouseEnter}
          onClick={onClick}
          target={target}
          title={title}
          rel={target === "_blank" ? "noopener noreferrer" : null}
          id={idName}
        >
          {text}
        </a>
      );
    }

    return (
      <Link
        to={href}
        className={className}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        title={title}
        activeClassName={activeClassName !== undefined ? activeClassName : "active"}
        partiallyActive={partiallyActive ?? true}
        state={state}
        id={idName}
        target={target}
      >
        {text}
      </Link>
    );
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      onMouseEnter={onMouseEnter}
      title={titleProp}
      id={idName}
    >
      {text}
    </a>
  );
};

NavigationLink.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  elements: PropTypes.oneOfType([PropTypes.object]),
  href: PropTypes.string,
  id: PropTypes.string,
  idName: PropTypes.string,
  loading: PropTypes.bool,
  loadingWithPostText: PropTypes.bool,
  loadingWithPreText: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  partiallyActive: PropTypes.bool,
  state: PropTypes.oneOfType([PropTypes.object]),
  target: PropTypes.string,
  title: PropTypes.string,
  typeOverwrite: PropTypes.string,
  urlParameter: PropTypes.string,
};

export default NavigationLink;
