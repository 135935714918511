import { createSlice } from "@reduxjs/toolkit";

import SspRegistrationData from "../components/ssp/sspRegistrationData";
import { trimObjectValues } from "../utils/formik/objectUtils";

const initialState = {
  sspRegistrationData: new SspRegistrationData(),
};

const SspRegistrationSlice = createSlice({
  initialState,
  name: "sspRegistration",
  reducers: {
    setSspRegistrationData: (state, { payload }) => {
      state.sspRegistrationData = {
        ...state.sspRegistrationData,
        ...trimObjectValues(payload),
      };
    },
  },
});

export const {
  setSspRegistrationData,
} = SspRegistrationSlice.actions;

export default SspRegistrationSlice.reducer;
