import IoBaseApiSlice from "./ioBaseApiSlice";
import DataLayer from "../../utils/dataLayer";
import { pseudoLogout } from "../sspSlice";

export const INVALID_VERIFICATION_CODE = "invalid_otp";

export const IoSspApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation({
      query: (data) => ({
        apiRequirements: ["session", "csrfToken"],
        data,
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/ciam/password`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
    generateDonorCardWallet: build.mutation({
      queryFn: async ({ apiRole, taskKey }, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          apiRequirements: ["session"],
          blob: true,
          url: `${process.env.GATSBY_IO_HOST}/ciam/urn:dkmsio:ciam:default:DKMS_DE:20211014/wallet`,
        });

        DataLayer.pushEvent("pp_ssp_wallet_download", {
          dimension_ssp_role: apiRole,
          dimension_ssp_status: taskKey,
          event_value: result?.error ? "fail" : "success",
        });

        if (!result?.error) {
          const a = document.createElement("a");
          a.href = (window.URL || window.webkitURL).createObjectURL(result);
          a.target = "_blank";
          a.download = "DKMS_DonorCard.pkpass";
          a.click();
        }

        return result;
      },
    }),
    getProfileContact: build.query({
      providesTags: ["SSP"],
      query: () => ({
        apiRequirements: ["session"],
        url: `${process.env.GATSBY_IO_HOST}/ciu/urn:dkmsio:vault:model::20210719:contact`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
    getProfileInfo: build.query({
      providesTags: ["SSP"],
      query: () => ({
        apiRequirements: ["session"],
        url: `${process.env.GATSBY_IO_HOST}/ciu/urn:dkmsio:vault:model::20210719:person`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
    getStatusInfo: build.query({
      providesTags: ["SSP"],
      query: () => ({
        apiRequirements: ["session"],
        url: `${process.env.GATSBY_IO_HOST}/reg/urn:dkmsio:vault:model::20210719:typingsample`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
    getUserAddress: build.query({
      providesTags: ["SSP"],
      query: () => ({
        apiRequirements: ["session"],
        url: `${process.env.GATSBY_IO_HOST}/ciu/urn:dkmsio:vault:model::20210719:address/primary`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
    login: build.mutation({
      // We invalidate old data on login to prevent data inconsistency
      invalidatesTags: ["SSP"],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          if (data.response === 200 || data.response === 202) {
            dispatch(pseudoLogout(false));
            window.location.href = `${process.env.GATSBY_SITE_URL}${process.env.GATSBY_SSP_URL_PREFIX || ""}`;
          }
        } catch {
          //
        }
      },
      query: ({
        user = "",
        password = "",
      }) => ({
        apiRequirements: ["session", "csrfToken"],
        data: {
          payload: {
            login_data: {
              password,
              user,
            },
          },
        },
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/ciam/${process.env.GATSBY_IO_CIAM_URN}/loginapi`,
      }),
    }),
    logout: build.mutation({
      // We don't invalidate the user data on logout.
      // Reason: The ssp-components are still mounted during logout und would refetch the
      // invalidated data although the user is no longer logged in.
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(pseudoLogout(false));

          window.location.href = data.payload.url;
        } catch {
          //
        }
      },
      query: () => ({
        apiRequirements: ["session"],
        url: `${process.env.GATSBY_IO_HOST}/ciam/${process.env.GATSBY_IO_CIAM_URN}/logout`,
      }),
    }),
    setIdentity: build.mutation({
      query: (data) => ({
        apiRequirements: ["session", "csrfToken"],
        data,
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/reg/urn:dkmsio:registration:sspidentity:DKMS_DE:20201130/identityset`,
      }),
      transformErrorResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.status === 400
          && baseQueryReturnValue.data?.detail?.error === INVALID_VERIFICATION_CODE) {
          return INVALID_VERIFICATION_CODE;
        }

        return baseQueryReturnValue;
      },
      transformResponse: ({ payload }) => payload,
    }),
    submitSspUser: build.mutation({
      query: (data) => ({
        apiRequirements: ["session", "csrfToken"],
        data,
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/reg/urn:dkmsio:registration:ssp:DKMS_DE:20201130/identityrequest`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
  }),
  overrideExisting: false,
});

export const {
  useSubmitSspUserMutation,
  useSetIdentityMutation,
  useChangePasswordMutation,
  useGenerateDonorCardWalletMutation,
  useGetProfileContactQuery,
  useGetProfileInfoQuery,
  useGetStatusInfoQuery,
  useGetUserAddressQuery,
  useLoginMutation,
  useLogoutMutation,
} = IoSspApiSlice;
